<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="12">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :PageResponse="'fiches'"
          :AllResponse="'allfiches'"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'Fiches'"
          :sheetname="'Fiches'"
          @open="OpenFicheForm"
          @hrf="OpenFicheForm"
          @rowselect="FicheChange"
          :showedit="false"
          :key="klist"
          :Add="true"
          :del="true"
          :del_disable="fiche.statut_id == 19 || fiche.detailcount > 0"
          :search_elm="search"
          @delete="deleteItem"
          :list_options="true"
          :isExpire="false"
          :VarScope="
            $store.state.isadmin || $store.state.auth.includes('04023')
              ? 1
              : null
          "
        >
        </listitemspage>
      </v-col>
    </v-row>
    <ficheform
      :item="fiche"
      :key="kf"
      :showForm="!isFicheClosed"
      :readonly="fiche.statut_id == 19"
      :attributes="attributes"
      :open_bts="open_bts"
      :shifts="shifts"
      :arrets="arrets"
      :dechets="dechets"
      :machines="machines"
      @close="closeFicheForm"
      @refresh="refresh"
    >
    </ficheform>
    <fichetransf
      :item="fiche"
      :key="kt"
      :showForm="!isTransfClosed"
      :readonly="fiche.statut_id == 19"
      :attributes="attributes"
      :shifts="shifts"
      :arrets="arrets"
      :dechets="dechets"
      :machines="machines"
      :agents="agents"
      :orders="open_orders.filter((elm) => elm.type_id == 1)"
      @close="closeFicheForm"
    >
    </fichetransf>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import FICHES from "../graphql/Production/FICHES.gql";
import ALLFICHES from "../graphql/Production/ALLFICHES.gql";
import DATA from "../graphql/Production/DATA.gql";
//import PRODUCT_LIST_DELETE from "../graphql/Product/PRODUCT_LIST_DELETE.gql";
import ACTUAL_ORDERS from "../graphql/Production/ACTUAL_ORDERS.gql";
import ACTUAL_BTS from "../graphql/Production/ALLBTS.gql";
import DELETE_FICHE from "../graphql/Production/DELETE_FICHE.gql";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    ficheform: () => import("../components/FicheForm.vue"),
    fichetransf: () => import("../components/FicheTransf.vue"),
  },
  props: {
    transf: { Type: Boolean, default: false },
  },
  data: () => ({
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editer: false,
    valider: false,
    klist: 100,
    kf: 300,
    kt: 400,
    search: "",
    drawer: null,
    selitem: {},
    headers: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "nofiche",
        enum: "NOFICHE",
        slot: "href",
        selected: true,
      },
      {
        text: "N° BT",
        value: "no_bt",
        enum: "NO_BT",
        hiden: true,
        selected: true,
      },
      {
        text: "N° OF",
        value: "no_order",
        enum: "NO_ORDER",
        hiden: true,
        selected: true,
      },

      {
        text: "Produit",
        value: "label",
        enum: "LABEL",
        hiden: true,
        selected: true,
      },
      {
        text: "Etape",
        value: "gamme_name",
        enum: "GAMME_NAME",
        hiden: true,
        selected: true,
      },
      {
        text: "Date",
        value: "fiche_date",
        slot: "date",
        enum: "FICHE_DATE",
        selected: true,
      },

      {
        text: "Shift",
        value: "shift_name",
        enum: "SHIFT_NAME",
        selected: true,
      },
      {
        text: "Machine",
        value: "machine_name",
        enum: "MACHINE_NAME",
        selected: true,
      },
      {
        text: "Operateur",
        value: "operator_name",
        enum: "OPERATOR_NAME",
        selected: true,
      },
      {
        text: "Statut",
        value: "statut_name",
        enum: "STATUT_NAME",
        slot: "chip",
        color: "color",
        selected: true,
      },
    ],
    open_bts: [],
    isFicheClosed: true,
    isTransfClosed: true,
    fiche: {},
    changed: false,
    loading: false,
    attributes: [],
    shifts: [],
    arrets: [],
    dechets: [],
    machines: [],
    agents: [],
    open_orders: [],
  }),

  computed: {
    Qselect() {
      return FICHES;
    },
    Qselectall() {
      return ALLFICHES;
    },
  },
  watch: {
    transf: {
      handler() {
        this.get_data();
      },
      deep: true,
    },
  },
  created() {},

  async mounted() {
    this.editer =
      this.$store.state.auth.includes("02023") || this.$store.state.isadmin;
    this.valider =
      this.$store.state.auth.includes("03023") || this.$store.state.isadmin;

    this.get_data();
  },

  methods: {
    async refresh() {
      if (!this.transf) {
        let v = {
          where: {
            AND: [
              { column: "QTE_REST", operator: "GT", value: 0 },
              { column: "STATUT_ID", value: 7 },
            ],
          },
        };
        let r = await this.requette(ACTUAL_BTS, v);
        if (r) this.open_bts = r.allbts;
      }
    },

    async get_data() {
      this.headers[2].hiden = this.transf;
      this.headers[3].hiden = this.transf;
      this.headers[4].hiden = this.transf;
      this.headers[5].hiden = this.transf;
      let v = {
        TypeScope: 1,
      };
      let r = await this.requette(DATA, v);
      if (r) {
        this.attributes = r.allattributes;
        this.shifts = r.shifts;
        this.arrets = r.arrets;
        this.dechets = r.dechets;
        this.machines = r.gammes_machines;
        this.agents = r.agents;
      }
      v = {
        where: {
          AND: [
            { column: "STATUT_ID", value: 7 },
            { column: "QTE_REST", operator: "GT", value: 0 },
          ],
        },
      };
      r = await this.requette(ACTUAL_ORDERS, v, "no-cache");
      if (r) this.open_orders = r.allorders;
      this.refresh();
      this.klist++;
    },
    async requette(query, v) {
      this.loading = true;
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async maj(query, v, r) {
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then(() => {
          if (r) this.klist++;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
    },
    async deleteItem(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir supprimer cette fiche?",
          ""
        )
      ) {
        let v = {
          id: item.id,
        };
        await this.maj(DELETE_FICHE, v);
        await this.refresh();
        this.klist++;
      }
    },
    FicheChange(item) {
      this.fiche = item;
      this.selitem = item;
    },
    OpenFicheForm(item) {
      this.fiche = item;
      if (this.transf) {
        this.isTransfClosed = false;
        this.kt++;
      } else {
        this.isFicheClosed = false;
        this.kf++;
      }
    },
    closeFicheForm(item) {
      this.selitem = item;
      this.fiche = item;
      if (this.transf) {
        this.isTransfClosed = true;
      } else {
        this.isFicheClosed = true;
      }
    },
  },
};
</script>
